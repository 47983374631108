// Import necessary dependencies and components from React and Material-UI libraries
import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import {
  useStarredEntities,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { TableProps } from '@backstage/core-components';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { useApi } from '@backstage/core-plugin-api';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { visuallyHidden } from '@mui/utils';
import { Edit, Delete } from '@mui/icons-material';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import {
  Entity,
  ANNOTATION_VIEW_URL,
  ANNOTATION_EDIT_URL,
  isSystemEntity,
  isResourceEntity,
} from '@backstage/catalog-model';
import { useNavigate } from 'react-router-dom';
import {
  handleWorkspaceDelete,
  handleWorkspaceEdit,
} from './workspace/entityActions';
import {
  handleSubscriptionDelete,
  handleSubscriptionEdit,
} from './subscription/entityActions';

// Styled component for a yellow star icon to indicate a starred entity
// This component overrides the default color of the Star icon to yellow.
const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

/**
 * Determines if an entity is related to a workspace.
 *
 * This function checks if the provided entity is a "System" entity (using the isSystemEntity utility)
 * and verifies that its spec.type is 'workspace'.
 *
 * @param {Entity} entity - The entity to check.
 * @returns {boolean} - Returns true if the entity is a workspace-related entity, false otherwise.
 */
export const isWorkspaceRelatedEntity = (entity: Entity): boolean => {
  return (
    isSystemEntity(entity) && (entity as Entity).spec?.type === 'workspace'
  );
};

/**
 * Determines if an entity is related to a subscription.
 *
 * This function checks if the provided entity is a "Resource" entity (using the isResourceEntity utility)
 * and verifies that its spec.type is 'subscription'.
 *
 * @param {Entity} entity - The entity to check.
 * @returns {boolean} - Returns true if the entity is a subscription-related entity, false otherwise.
 */
export const isSubscriptionRelatedEntity = (entity: Entity): boolean => {
  return isResourceEntity(entity) && entity.spec?.type === 'subscription';
};

// Define custom actions for the catalog table
export const customActions: TableProps<CatalogTableRow>['actions'] = [
  // Action to view the entity in a new tab
  ({ entity }) => {
    const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
    const title = 'View';

    return {
      // Icon and tooltip for the action
      icon: () => (
        <>
          <Typography style={visuallyHidden}>{title}</Typography>
          <OpenInNew fontSize="small" />
        </>
      ),
      tooltip: title,
      disabled: !url,
      onClick: () => {
        if (!url) return;
        window.open(url, '_blank');
      },
    };
  },
  // Action to edit the entity or redirect to the update template
  ({ entity }) => {
    const url: string | undefined =
      entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
    const catalogApi = useApi(catalogApiRef);
    const navigate = useNavigate();
    const title: string = 'Edit';

    return {
      // Icon and tooltip for the edit action
      icon: () => (
        <>
          <Typography style={visuallyHidden}>{title}</Typography>
          <Edit fontSize="small" />
        </>
      ),
      tooltip: title,
      disabled:
        !isWorkspaceRelatedEntity(entity) &&
        !url &&
        !isSubscriptionRelatedEntity(entity),
      onClick: async () => {
        if (isWorkspaceRelatedEntity(entity)) {
          await handleWorkspaceEdit(entity, catalogApi, navigate);
        } else if (isSubscriptionRelatedEntity(entity)) {
          await handleSubscriptionEdit(entity, catalogApi, navigate);
        } else {
          if (!url) return;
          window.open(url, '_blank');
        }
      },
    };
  },
  // Action to delete the entity or redirect to the delete template
  ({ entity }) => {
    const navigate = useNavigate();
    const title: string = 'Delete';

    return {
      // Icon and tooltip for the edit action
      icon: () => (
        <>
          <Typography style={visuallyHidden}>{title}</Typography>
          <Delete fontSize="small" />
        </>
      ),
      tooltip: title,
      disabled:
        !isWorkspaceRelatedEntity(entity) &&
        !isSubscriptionRelatedEntity(entity),
      onClick: async () => {
        if (isWorkspaceRelatedEntity(entity)) {
          await handleWorkspaceDelete(entity, navigate);
        } else if (isSubscriptionRelatedEntity(entity)) {
          await handleSubscriptionDelete(entity, navigate);
        } else {
          return;
        }
      },
    };
  },
  // Action to toggle the starred state of the entity
  ({ entity }) => {
    const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
    const isStarred = isStarredEntity(entity);
    const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

    return {
      // Icon and tooltip for the star action
      cellStyle: { paddingLeft: '1em' },
      icon: () => (
        <>
          <Typography style={visuallyHidden}>{title}</Typography>
          {isStarred ? <YellowStar /> : <StarBorder />}
        </>
      ),
      tooltip: title,
      onClick: () => toggleStarredEntity(entity),
    };
  },
];
