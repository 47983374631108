import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import PolicyIcon from '@mui/icons-material/Policy';
import ExtensionIcon from '@mui/icons-material/Extension';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined';
import MXPSidebarLogoIcon from './MXPSidebarLogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarSubmenuItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  DashboardIcon,
  DocsIcon,
} from '@backstage/core-components';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import { Config } from '../../../config';

const useSidebarLogoStyles = makeStyles({
  appTitle: {
    lineHeight: '52px',
    fontFamily: 'FKCARIAD-Light',
    fontSize: '32px',
    color: 'white',
  },
  link: {
    margin: '16px auto 0 !important',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    gap: '10px',
  },
  inlineSubmenu: {
    width: '100%',
    backgroundColor: 'rgb(0 0 0 / 10%)',
    '&  > div > a': {
      paddingLeft: '25px',
    },
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <Link to="/" underline="none" className={classes.link} aria-label="Home">
      <MXPSidebarLogoIcon ascended={isOpen} />
      {isOpen && <span className={classes.appTitle}>MEGA</span>}
    </Link>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const configApi = useApi(configApiRef);
  const classes = useSidebarLogoStyles();

  const { documentationUrl, costDashboardUrl } =
    configApi.get<Config['megatron']['mxp']>('megatron.mxp');

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SidebarSearchModal />
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={() => <HomeIcon />} to="catalog" text="Home" />
          <SidebarItem
            icon={() => <CategoryIcon />}
            to="product-meta"
            text="Product Meta"
          />
          <MyGroupsSidebarItem
            singularTitle="My Group"
            pluralTitle="My Groups"
            icon={() => <GroupIcon />}
          />
          <SidebarItem
            icon={() => <ExtensionIcon />}
            to="api-docs"
            text="APIs"
          />
          <SidebarItem
            icon={() => <LibraryBooksIcon />}
            to="create"
            text="Templates"
          />
          <SidebarItem
            icon={() => <CreateComponentIcon />}
            to="catalog-import"
            text="Add..."
          />
          <SidebarDivider />
          <div className={classes.inlineSubmenu}>
            <SidebarSubmenuItem
              title="Documentation"
              to={documentationUrl}
              icon={DocsIcon}
            />
            <SidebarSubmenuItem
              title="Cost Dashboard"
              to={costDashboardUrl}
              icon={DashboardIcon}
            />
          </div>
          {/* End global nav */}
          <SidebarDivider />
          <SidebarScrollWrapper>
            {/* Items in this group will be scrollable if they run out of space */}
          </SidebarScrollWrapper>
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
        <SidebarItem
          to="https://cariad.technology/de/en/legal-notice.html"
          target="_blank"
          aria-label="Developer Experience Portal"
          style={{ textDecoration: 'none' }}
          icon={() => <PolicyIcon />}
          text="Legal Notice"
        />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
