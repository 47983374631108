import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  svg: ({ ascended }: any) => ({
    height: 50,
    width: ascended ? 60 : 'auto',
  }),
});

export type MxpLogoIconProps = {
  className?: string;
  ascended?: boolean;
};

const LogoIcon = ({ className = '', ascended }: MxpLogoIconProps) => {
  const classes = useStyles({ ascended });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${ascended ? '184 124' : '120 120'} `}
      className={`${classes.svg} ${className}`}
    >
      {ascended ? (
        <>
          <path
            d="M183 16v89c0 9-7 16-16 16H78c-9 0-15-7-15-16V16c0-9 6-15 15-15h89c9 0 16 6 16 15z"
            stroke="#1e7b68"
            strokeWidth="2"
            fill="#1d0638"
          />
          <path
            d="M150 38l-9 71c-1 8-8 13-15 12l-72-9c-7 0-13-8-12-15l9-71c1-8 8-14 16-13l71 9c7 1 13 8 12 16z"
            fill="#1d4050"
          />
          <path
            d="M116 58l-11 58c-1 6-7 9-12 8l-59-11c-5-1-9-7-8-12l12-58c1-6 6-10 12-9l58 12c5 1 9 6 8 12z"
            fill="#1e7b68"
          />
          <path
            d="M84 78l-18 43c-1 4-5 5-8 4l-44-17c-3-2-5-6-4-9l18-43c1-4 5-5 9-4l43 18c3 1 5 5 4 8z"
            fill="#1eb57f"
          />
          <path
            d="M54 101l-25 24c-1 1-2 1-3 0L1 101c-1-1-1-3 0-4l25-25c1-1 2-1 3 0l25 25c1 1 1 3 0 4z"
            fill="#1eef97"
          />
        </>
      ) : (
        <>
          <path
            d="M120 16v88c0 8-8 16-16 16H16c-8 0-16-8-16-16V16C0 8 8 0 16 0h88c8 0 16 8 16 16z"
            fill="#1d0638"
          />
          <path
            d="M100 34v72c0 8-6 14-14 14H14c-8 0-14-6-14-14V34c0-8 6-14 14-14h72c8 0 14 6 14 14z"
            fill="#1d4050"
          />
          <path
            d="M80 50v60c0 5-5 10-10 10H10c-5 0-10-5-10-10V50c0-5 5-10 10-10h60c5 0 10 5 10 10z"
            fill="#1e7b68"
          />
          <path
            d="M60 66v48c0 3-3 6-7 6H6c-3 0-6-3-6-6V66c0-3 3-6 6-6h47c4 0 7 3 7 6z"
            fill="#1eb57f"
          />
          <path
            d="M40 82v35c0 2-1 3-3 3H3c-2 0-3-1-3-3V82c0-1 1-2 3-2h34c2 0 3 1 3 2z"
            fill="#1eef97"
          />
        </>
      )}
    </svg>
  );
};

export default LogoIcon;
