// Import the required modules and components from the Backstage Scaffolder plugin
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

// Import the custom WorkspaceId component
import { OwnedWorkspacePicker } from './OwnedWorkspacePickerExtension';

// Define and export the WorkspacePickerExtension. by providing it to the Scaffolder plugin
export const OwnedWorkspacePickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    // Specify the name of the field extension as 'WorkspaceId'
    name: 'OwnedWorkspacePicker',
    // Set the component to be used for this field extension
    component: OwnedWorkspacePicker,
  }),
);
