import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { JumphostDetails } from './JumphostDetailsExtension';

export const JumphostDetailsExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'JumphostDetails',
    component: JumphostDetails,
  }),
);
