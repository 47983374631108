import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import React, { useEffect, useState } from 'react';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import {
  RELATION_OWNED_BY,
  RELATION_MEMBER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import useAsync from 'react-use/esm/useAsync';

import { Entity } from '@backstage/catalog-model';

export type FormData = {
  workspaceId: string | undefined;
};

export const OwnedWorkspacePicker = ({
  onChange,
  required,
  formData,
}: FieldExtensionComponentProps<any>) => {
  // Get the catalog API reference to access application configuration settings.
  const catalogApi = useApi(catalogApiRef);

  const INITIAL_FORM_DATA = {
    workspaceId: '',
  };
  const [values, setValues] = useState<FormData>(INITIAL_FORM_DATA);
  const [systems, setSystems] = React.useState<any[] | null>(null);
  // State to manage any potential error messages (initially set to null, indicating no error)
  const [error, setError] = useState<string | null>(null);
  const identityApi = useApi(identityApiRef);

  // Get the groups in which user is a owner and contributor.
  const { value: entityRefs } = useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const userEntity = await catalogApi.getEntityByRef(identity.userEntityRef);
    const memberOfGroups = getEntityRelations(userEntity, RELATION_MEMBER_OF);
    const relatedOwnerGroups = memberOfGroups
      .filter(group => {
        if (group.name.endsWith('-owner')) {
          return group;
        }

        if (group.name.endsWith('-contributor')) {
          return group.name.replace('-contributor', '-owner');
        }

        return null;
      })
      .map(group => stringifyEntityRef(group));

    return relatedOwnerGroups;
  });
  /**
   * useEffect hook to fetch the token when the component mounts.
   * Fetch the access token from Microsoft API and save it in template secrets.
   */
  useEffect(() => {
    const getUserSystems = async () => {
      let userSystemList: Entity[] = [];
      const fields = ['metadata', 'kind'];
      try {
        // Fetching userSystemList from catalog API
        const { items } = await catalogApi.getEntities({
          filter: {
            kind: 'System',
            'spec.type': 'workspace',
            [`relations.${RELATION_OWNED_BY}`]: entityRefs || [],
          },
          fields,
        });
        const newInsertedItemIndex =
          systems &&
          systems.findIndex(
            resource =>
              `${resource?.metadata?.name} (${resource?.metadata?.annotations?.workspaceId})` ===
              formData,
          );
        // selecting the updated list of the system on the basis of the ownerOf workspace
        userSystemList =
          newInsertedItemIndex || (newInsertedItemIndex === 0 && systems)
            ? systems
            : items;
        if (formData && userSystemList.length > 0) {
          const index = userSystemList.findIndex(
            resource =>
              `${resource?.metadata?.name} (${resource?.metadata?.annotations?.workspaceId})` ===
              formData,
          );
          if (index === -1) {
            userSystemList[userSystemList.length] = {
              kind: 'System',
              apiVersion: '1',
              metadata: {
                name: formData.split(' ')[0],
                annotations: {
                  workspaceId: formData.split(' ')[1],
                },
              },
            };
            onChange(
              `${userSystemList[index]?.metadata?.name} (${userSystemList[index]?.metadata?.annotations?.workspaceId})`,
            );
          }
          setValues({
            workspaceId: `${userSystemList[index]?.metadata?.name} (${userSystemList[index]?.metadata?.annotations?.workspaceId})`,
          });
        }
        setSystems(userSystemList);
      } catch (systemError) {
        // eslint-disable-next-line no-console
        setError(
          `Failed to fetch user system from catalog API: ${
            systemError instanceof Error
              ? systemError.message
              : String(systemError)
          }`,
        );
      }
    };

    // Function to fetch user system
    getUserSystems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogApi, formData, entityRefs]);

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
    setValues({ workspaceId: event.target.value });
  };

  // If there's an error, display an error message with an error icon
  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 1.5,
          padding: 1.5,
        }}
      >
        <ErrorIcon color="error" />
        <Typography variant="body1" sx={{ fontWeight: 500, color: 'red' }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <form>
      <FormControl
        data-testid="workspace-id-form"
        margin="normal"
        fullWidth
        required={required}
      >
        <InputLabel htmlFor="workspaceId">Workspace</InputLabel>
        <Select
          labelId="workspace-id-label"
          id="workspace-id"
          value={values.workspaceId}
          onChange={handleChange}
          label="Workspace"
        >
          {systems?.map(item => (
            <MenuItem
              key={`workspace-id-${item.metadata.name}`}
              value={`${item.metadata.name} (${item?.metadata?.annotations?.workspaceId})`}
            >
              {`${item.metadata.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  );
};
