// Import necessary dependencies and components from React and Material-UI libraries
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { Entity } from '@backstage/catalog-model';
import { NavigateFunction } from 'react-router-dom';
import {
  EntityGroups,
  extractGroupEntityDetails,
  getOwnerEntity,
} from '../utils';

/**
 * Handles the process of editing a workspace entity.
 *
 * This function extracts the necessary workspace details (ID, name, description, owners, etc.)
 * and redirects the user to the workspace update template page with pre-filled form data.
 *
 * @param {Entity} entity - The workspace entity to be edited.
 * @param {CatalogApi} catalogApi - The API used to fetch additional entities from the catalog.
 * @param {NavigateFunction} navigate - The function used to redirect to the update template page.
 * @throws {Error} - Throws an error if workspace details extraction or redirection fails.
 */
export const handleWorkspaceEdit = async (
  entity: Entity,
  catalogApi: CatalogApi,
  navigate: NavigateFunction,
) => {
  try {
    const workspaceId: string | undefined =
      entity.metadata.annotations?.workspaceId;
    const workspaceName: string = entity.metadata.name || '';
    const workspaceDescription: string = entity.metadata.description || '';
    if (!workspaceId)
      throw new Error(
        'Workspace ID not found in the system entity annotations',
      );
    // Retrieve the system owner entity by querying the catalog using the entity reference
    const ownerEntity: Entity = await getOwnerEntity(entity, catalogApi);

    // Extract entity details and redirect to the update template
    const entityDetail: EntityGroups = await extractGroupEntityDetails(
      ownerEntity,
      catalogApi,
    );
    const formData = encodeURIComponent(
      JSON.stringify({
        workspaceId: workspaceId,
        name: workspaceName,
        description: workspaceDescription,
        owners: entityDetail.owners,
        contributors: entityDetail.contributors,
        readers: entityDetail.readers,
      }),
    );
    const templateUrl = `/create/templates/default/mega-workspace-update-template?formData=${formData}`;
    // Navigate to the template update page with the form data pre-filled
    navigate(templateUrl);
  } catch (error: any) {
    throw new Error(
      `Failed to extract entity details or redirect to edit workspace template: ${
        error instanceof Error ? error.message : String(error)
      }`,
    );
  }
};

/**
 * Handles the process of deleting a workspace entity.
 *
 * This function retrieves the workspace ID from the entity and redirects the user to the workspace
 * delete template page with the workspace ID pre-filled in the form data.
 *
 * @param {Entity} entity - The workspace entity to be deleted.
 * @param {NavigateFunction} navigate - The function used to redirect to the delete template page.
 * @throws {Error} - Throws an error if the workspace ID is missing or the redirection fails.
 */
export const handleWorkspaceDelete = async (
  entity: Entity,
  navigate: NavigateFunction,
) => {
  try {
    const workspaceId: string | undefined =
      entity.metadata.annotations?.workspaceId;
    const workspaceName: string = entity.metadata.name;
    if (!workspaceId)
      throw new Error(
        'Workspace ID not found in the system entity annotations',
      );

    // Redirect to the delete template
    const formData = encodeURIComponent(
      JSON.stringify({
        workspaceId: workspaceId,
        workspaceName: workspaceName,
      }),
    );
    const templateUrl = `/create/templates/default/mega-workspace-delete-template?formData=${formData}`;
    // Navigate to the template delete page with the form data pre-filled
    navigate(templateUrl);
  } catch (error: any) {
    throw new Error(
      `Failed to extract entity details or redirect to delete workspace template : ${
        error instanceof Error ? error.message : String(error)
      }`,
    );
  }
};
