import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  AuthTokenPickerExtension,
  AzureUserPickerExtension,
  OwnedWorkspacePickerExtension,
} from '@internal/backstage-plugin-scaffolder-frontend-module-mega-actions';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import DarkIcon from '@mui/icons-material/Brightness2';
import LightIcon from '@mui/icons-material/WbSunny';
import { UnifiedThemeProvider } from '@backstage/theme';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import {
  customUnifiedDarkTheme,
  customUnifiedLightTheme,
} from './components/theme/cariad-theme';
import CustomSignInPage from './components/signin/SignInPage';
import { customActions } from '@internal/backstage-plugin-scaffolder-frontend-module-mega-actions';
import { ProductMetaPage } from '@internal/plugin-product-meta';
import { JumphostDetailsExtension } from '@internal/backstage-plugin-scaffolder-jumphost-react';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    // Sign In Provided with Azure Auth.
    SignInPage: props => (
      <CustomSignInPage
        {...props}
        auto
        provider={{
          id: 'microsoft-auth-provider',
          title: 'Azure',
          message: 'Sign in using Azure',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  themes: [
    {
      id: 'custom-light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedLightTheme}
          children={children}
        />
      ),
    },
    {
      id: 'custom-dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider
          theme={customUnifiedDarkTheme}
          children={children}
        />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route
      path="/catalog"
      element={<CatalogIndexPage actions={customActions} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          // Filter function to exclude a specific template from being shown
          templateFilter={entity =>
            !(
              ([
                'mega-subscription-update-template',
                'mega-subscription-delete-template',
              ].some(name => entity?.metadata?.name?.includes(name)) &&
                entity?.spec?.type?.includes('subscription')) ||
              ([
                'mega-workspace-update-template',
                'mega-workspace-delete-template',
              ].some(name => entity?.metadata?.name?.includes(name)) &&
                entity?.spec?.type?.includes('workspace'))
            )
          }
        />
      }
    >
      <ScaffolderFieldExtensions>
        <AuthTokenPickerExtension />
        <AzureUserPickerExtension />
        <OwnedWorkspacePickerExtension />
        <JumphostDetailsExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/product-meta" element={<ProductMetaPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
