// Import necessary dependencies and components from React and Material-UI libraries
import { CatalogApi } from '@backstage/plugin-catalog-react';

import { Entity, ResourceEntity } from '@backstage/catalog-model';
import { NavigateFunction } from 'react-router-dom';
import {
  EntityGroups,
  extractGroupEntityDetails,
  getOwnerEntity,
} from '../utils';

/**
 * Handles the process of editing a subscription entity.
 *
 * This function extracts the necessary subscription details (ID, name, environment, description, owners, etc.)
 * and redirects the user to the subscription update template page with pre-filled form data.
 *
 * @param {Entity} entity - The subscription entity to be edited.
 * @param {CatalogApi} catalogApi - The API used to fetch additional entities from the catalog.
 * @param {NavigateFunction} navigate - The function used to redirect to the update template page.
 * @throws {Error} - Throws an error if subscription details extraction or redirection fails.
 */
export const handleSubscriptionEdit = async (
  entity: Entity,
  catalogApi: CatalogApi,
  navigate: NavigateFunction,
) => {
  try {
    const resourceEntity = entity as ResourceEntity;
    const subscriptionId: string | undefined =
      resourceEntity.metadata.annotations?.subscriptionId;
    const subscriptionName: string = resourceEntity.metadata.name || '';
    const subscriptionEnvironment: string =
      resourceEntity.metadata.annotations?.environment || '';
    const subscriptionDescription: string =
      resourceEntity.metadata.description || '';
    const systemEntityRef: string | undefined = resourceEntity.spec.system;
    if (!subscriptionId)
      throw new Error(
        'Subscription ID missing in subscription resource entity',
      );
    if (!systemEntityRef)
      throw new Error(
        'System entity reference not found in subscription resource entity',
      );

    const systemEntity = await catalogApi.getEntityByRef(systemEntityRef);

    if (!systemEntity)
      throw new Error(
        'System entity not found in catalog using the subscription resource entity',
      );
    const workspaceId: string | undefined =
      systemEntity.metadata.annotations?.workspaceId;

    if (!workspaceId)
      throw new Error(
        'Workspace ID not found in the system entity annotations',
      );

    const workspaceName: string = systemEntity.metadata.name;

    // Retrieve the system owner entity by querying the catalog using the entity reference
    const ownerEntity: Entity = await getOwnerEntity(entity, catalogApi);

    // Extract entity details and redirect to the update template
    const entityDetail: EntityGroups = await extractGroupEntityDetails(
      ownerEntity,
      catalogApi,
    );
    const formData = encodeURIComponent(
      JSON.stringify({
        subscriptionId: subscriptionId,
        name: subscriptionName,
        description: subscriptionDescription,
        workspace: `${workspaceName} (${workspaceId})`,
        environment: subscriptionEnvironment,
        owners: entityDetail.owners,
        contributors: entityDetail.contributors,
        readers: entityDetail.readers,
      }),
    );
    const templateUrl = `/create/templates/default/mega-subscription-update-template?formData=${formData}`;
    // Navigate to the template update page with the form data pre-filled
    navigate(templateUrl);
  } catch (error: any) {
    throw new Error(
      `Failed to extract entity details or redirect to edit subscription template: ${
        error instanceof Error ? error.message : String(error)
      }`,
    );
  }
};

/**
 * Handles the process of deleting a subscription entity.
 *
 * This function retrieves the subscription ID from the entity and redirects the user to the subscription
 * delete template page with the subscription ID pre-filled in the form data.
 *
 * @param {Entity} entity - The subscription entity to be deleted.
 * @param {NavigateFunction} navigate - The function used to redirect to the delete template page.
 * @throws {Error} - Throws an error if the subscription ID is missing or the redirection fails.
 */
export const handleSubscriptionDelete = async (
  entity: Entity,
  navigate: NavigateFunction,
) => {
  try {
    const subscriptionId: string | undefined =
      entity.metadata.annotations?.subscriptionId;
    const subscriptionName: string = entity.metadata.name;
    if (!subscriptionId)
      throw new Error(
        'Subscription ID not found in the resource entity annotations',
      );

    // Redirect to the delete template
    const formData = encodeURIComponent(
      JSON.stringify({
        subscriptionId: subscriptionId,
        subscriptionName: subscriptionName,
      }),
    );
    const templateUrl = `/create/templates/default/mega-subscription-delete-template?formData=${formData}`;
    // Navigate to the template delete page with the form data pre-filled
    navigate(templateUrl);
  } catch (error: any) {
    throw new Error(
      `Failed to extract entity details or redirect to delete subscription template : ${
        error instanceof Error ? error.message : String(error)
      }`,
    );
  }
};
