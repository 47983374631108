import {
  CatalogApi,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_HAS_MEMBER,
  RELATION_PARENT_OF,
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';

/**
 * Interface representing the final extracted result from an entity.
 * This interface defines the structure of the EntityGroups object,
 * which includes arrays for owners, contributors, and readers.
 */
export interface EntityGroups {
  owners: string[];
  contributors: string[];
  readers: string[];
}

/**
 * Retrieves the owner entity by querying the entity catalog.
 *
 * @param {Entity} entity - The entity to retrieve the owner from.
 * @param {CatalogApi} catalogApi - The API used to fetch entities from the catalog.
 * @returns {Promise<Entity>} - A promise that resolves to the owner entity.
 * @throws {Error} - Throws an error if the entity has no relations,
 *                   if the owner relation is not found, or if the entity fetching fails.
 */

export const getOwnerEntity = async (
  entity: Entity,
  catalogApi: CatalogApi,
): Promise<Entity> => {
  const ownerRefs: CompoundEntityRef[] = getEntityRelations(
    entity,
    RELATION_OWNED_BY,
  );

  // If no owner references are found, throw an error
  if (ownerRefs.length === 0) {
    throw new Error('Owner relation not found.');
  }

  try {
    const targetEntity = await catalogApi.getEntityByRef(ownerRefs[0]);
    if (!targetEntity) {
      throw new Error('Owner entity not found.');
    }
    return targetEntity;
  } catch (error) {
    throw new Error(
      `Failed to get Owner entity.${
        error instanceof Error ? error.message : String(error)
      }`,
    );
  }
};

/**
 * Helper function to fetch members based on relation type.
 * This function retrieves related entities from the catalog and extracts
 * the members array if available.
 *
 * @param catalogApi - The Catalog API instance used to fetch the related entity.
 * @param targetRef - The reference to the related entity.
 * @returns A promise resolving to an array of member identifiers.
 */
export const fetchMembers = async (
  catalogApi: CatalogApi,
  targetRef: string,
): Promise<string[]> => {
  const relatedEntity = await catalogApi.getEntityByRef(targetRef);
  if (!relatedEntity) {
    return [];
  }

  const memberRefs: CompoundEntityRef[] = getEntityRelations(
    relatedEntity,
    RELATION_HAS_MEMBER,
  );
  return memberRefs.map(ref => ref.name);
};

/**
 * Extracts detailed information from a Backstage entity.
 * This function processes an entity to extract its name, description,
 * and the related owners, contributors, and readers by analyzing the entity's
 * relations and using the fetchMembers helper function.
 *
 * @param entity - The entity to extract details from.
 * @param catalogApi - The Catalog API instance used to fetch related entities.
 * @returns A promise resolving to an object containing extracted details.
 */
export const extractGroupEntityDetails = async (
  entity: Entity,
  catalogApi: CatalogApi,
): Promise<EntityGroups> => {
  // Extract basic metadata and initialize empty arrays for relationships

  const owners: string[] = entity.spec?.members
    ? (entity.spec.members as string[])
    : [];
  const contributors: string[] = [];
  const readers: string[] = [];

  // Return early if no relations exist
  if (!entity.relations || !Array.isArray(entity.relations)) {
    return { owners, contributors, readers };
  }

  // Collect promises to fetch members based on relations
  const fetchMemberDetails = getEntityRelations(entity, RELATION_PARENT_OF).map(
    async relation => {
      if (relation.name.includes('contributor')) {
        const contributorsFetched: string[] = await fetchMembers(
          catalogApi,
          stringifyEntityRef(relation),
        );
        contributors.push(...contributorsFetched);
      } else if (relation.name.includes('reader')) {
        const readersFetched: string[] = await fetchMembers(
          catalogApi,
          stringifyEntityRef(relation),
        );
        readers.push(...readersFetched);
      }
    },
  );

  // Await all fetch operations in parallel
  await Promise.all(fetchMemberDetails);

  // Return the constructed EntityGroups object
  return {
    owners,
    contributors,
    readers,
  };
};
