// Import the required modules and components from the Backstage Scaffolder plugin
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

// Import the custom AuthTokenPicker component
import { AuthTokenPicker } from './AuthTokenPickerExtension';

// Define and export the AuthTokenPickerExtension by providing it to the Scaffolder plugin
export const AuthTokenPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    // Specify the name of the field extension as 'AuthTokenPicker'
    name: 'AuthTokenPicker',
    // Set the component to be used for this field extension
    component: AuthTokenPicker,
  }),
);
